<template>
  <div>
    <v-navigation-drawer v-model="showMobileDrawer" absolute temporary right>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
          <v-list-item @click="$router.push('/dashboard')">
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$router.push('/products')">
            <v-list-item-title>Bag Stock</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$router.push('/invites')">
            <v-list-item-title>Invites</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$router.push('/approvals')">
            <v-list-item-title>Approvals</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$router.push('/investors')">
            <v-list-item-title>Consignors</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$router.push('/settings')">
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- <v-app-bar :color="$vuetify.theme.dark ? 'dark' : 'white'" :dark="$vuetify.theme.dark" -->
    <v-app-bar color="dark" :dark="true" class="px-sm text-left shadow-sm" height="75" app clipped-left>
      <!-- <v-app-bar-nav-icon
                v-ripple="{class: 'primary--text'}"
                @click="toggleVerticalSaasSidebarDrawer"
            /> -->
      <v-progress-linear :active="getThemeMode.isLoading" :indeterminate="getThemeMode.isLoading" absolute bottom
        color="primary" />

      <v-toolbar-title>
        <img @click.prevent="$router.push('/')" height="20" class="logo-img"
          :src="require('@/assets/images/logo-light.svg')" />
      </v-toolbar-title>

      <v-spacer />

      <!-- <v-badge
                bordered
                overlap
                content="3"
                color="red"
                offset-x="22"
                offset-y="22"
            >
                <v-btn icon @click="notificationDrawer = !notificationDrawer">
                    <v-icon>mdi-bell</v-icon>
                </v-btn>
            </v-badge> -->

      <!-- <v-btn icon @click="searchDrawer = !searchDrawer">
                <v-icon>mdi-magnify</v-icon>
            </v-btn> -->

      <!-- Mobile -->
      <div class="d-block d-md-none">
        <v-app-bar-nav-icon @click.stop="showMobileDrawer = !showMobileDrawer"></v-app-bar-nav-icon>
      </div>
      <!-- / Mobile -->

      <!-- Desktop -->
      <div class="d-none d-md-block">
        <v-chip pill class="transparent py-5" @click="$router.push('/dashboard')">
          Dashboard
        </v-chip>

        <v-chip pill class="transparent py-5" @click="$router.push('/products')">
          Bag Stock
        </v-chip>

        <v-chip pill class="transparent py-5" @click="$router.push('/invites')">
          Invites
        </v-chip>

        <v-chip pill class="transparent py-5" @click="$router.push('/approvals')">
          Approvals
        </v-chip>

        <v-chip pill class="transparent py-5" @click="$router.push('/investors')">
          Consignors
        </v-chip>

        <v-chip pill class="transparent py-5" @click="$router.push('/settings')">
          Settings
        </v-chip>

        <v-chip pill class="transparent py-5" @click="logout"> Logout </v-chip>
      </div>
      <!-- / Desktop -->
    </v-app-bar>
    <!-- userDrawer -->
    <v-navigation-drawer v-model="userDrawer" fixed right="" height="100%" temporary floating width="350">
      <user-drawer>
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="userDrawer = !userDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button @click.native="logoutUser" text="Logout" block bg-color="primary lighten-5 primary--text"
            icon-name="mdi-logout" />
        </div>
      </template>
    </v-navigation-drawer>

    <!-- notificationDrawer  -->
    <v-navigation-drawer v-model="notificationDrawer" fixed right="" height="100%" temporary floating width="350">
      <notification-drawer>
        <template v-slot:notificationDrawerCloseButton>
          <v-btn icon color @click.stop="notificationDrawer = !notificationDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </notification-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button text="View All Notifications" block bg-color="primary lighten-5 primary--text" />
        </div>
      </template>
    </v-navigation-drawer>
    <!-- searchDrawer -->
    <v-navigation-drawer v-model="searchDrawer" fixed right="" height="100%" temporary floating width="380">
      <search-drawer>
        <template v-slot:searchDrawerCloseButton>
          <v-btn icon color @click.stop="searchDrawer = !searchDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </search-drawer>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UserDrawer from "../common-drawer/UserDrawer.vue";
import NotificationDrawer from "../common-drawer/NotificationDrawer.vue";
import SearchDrawer from "../common-drawer/SearchDrawer.vue";
export default {
  name: "VerticallAppBar",
  components: {
    UserDrawer,
    NotificationDrawer,
    SearchDrawer,
  },
  computed: {
    ...mapGetters(["getThemeMode"]),

    ...mapGetters("auth", {
      user: "user",
    }),
  },
  data() {
    return {
      userDrawer: false,
      notificationDrawer: false,
      searchDrawer: false,
      showMobileDrawer: false
    };
  },
  methods: {
    ...mapActions("auth", {
      logout: "logout",
    }),

    ...mapActions(["changeVerticalSaasSidebarDrawer"]),
    toggleVerticalSaasSidebarDrawer() {
      this.changeVerticalSaasSidebarDrawer();

      // this.$emit("update:mini-variant");
      // console.log("check");
    },
  },
};
</script>

<style scoped>
.logo-img {
  margin-top: 4px;
  cursor: pointer;
}
</style>
